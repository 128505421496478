<script setup lang="ts">
import { VueShowdown } from 'vue-showdown'
import type { showdown } from 'vue-showdown';

defineProps({
  htmlInput: {
    type: String,
    required: true,
  },
})

const config = useRuntimeConfig();
const showdownExtensions = (): showdown.ShowdownExtension[] => [
  {
    type: 'lang',
    regex: /(\!\[.*\]\(\/uploads\/[^"]*)\)/gm,
    replace: (_m: string, p1: string) => {
      return p1.replace('/uploads/', `${config.public.baseUrl}/uploads/format_webp/`)
    }
  },
]
const showdownOptions: showdown.ShowdownOptions = {
  simpleLineBreaks: true,
}
</script>

<template>
  <VueShowdown
    class="html-formatter flex flex-col"
    :markdown="htmlInput"
    :options="showdownOptions"
    :extensions="[showdownExtensions]"
  />
</template>

<style lang="scss">
.html-formatter {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 500;

  blockquote {
    background-color: #F0F9FF;
    padding: 24px 32px;
    border-radius: 0.5rem;

    &::before {
      background: url('/quote-icon.svg');
      background-size: contain;
      content: "";
      display: block;
      height: 1.25rem;
      margin-bottom: 1rem;
      width: 1.5rem;
    }
  }

  & > ul {
    list-style-type: none !important;
    margin-left: 0.75rem;
    display: block;

    & > li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding-left: 1.5rem;
      margin-bottom: 2rem;
      font-family: 'Raleway', sans-serif;
      line-height: 22px;
      font-size: 16px;
      font-weight: 500;

      &::before {
        content: '\2022';
        color: #03569a;
        font-size: 35px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        position: absolute;
      }
    }
  }

  h1 {
    font-size: 90px;
    line-height: 1;
    font-weight: 600;
    color: #303133;
  }

  h2 {
    font-size: 40px;
    line-height: 56px;
    font-weight: 600;
    color: #303133;
  }

  h3 {
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
    color: #303133;
  }

  h4 {
    font-size: 30px;
    font-size: 40px;
    font-weight: 600;
    color: #303133;
  }

  h5 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    color: #303133;
  }

  h6 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: #303133;
  }


  & > code,
  & > p {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 500;

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }

  & > h3 {
    font-weight: bold;
    color: var(--vt-c-text-dark);
  }
}
</style>
