export enum Routes {
  HOME = '/',
  SERVICES = '/services',
  DEV_MARKET = '/dev-marketplace',
  PORTFOLIO = '/portfolio',
  CASE_STUDY = '/case-study',
  CAREERS = '/careers',
  OUR_MODEL = '/our-model',
  BLOG = '/blog',
  CONTACT = '/contact',
  JOBOFFER = '/job-offer',
  SOFTWARE = '/software',
  DELIVERY = '/delivery',

}

export enum RoutesName {
  SERVICES = 'Services',
  DEV_MARKET = 'Dev marketplace',
  CASE_STUDY = 'Case study',
  PORTFOLIO = 'Portfolio',
  CAREERS = 'Careers',
  OUR_MODEL = 'Our model',
  BLOG = 'Blog',
  CONTACT = 'Contact us!',
  HOME = 'Home',
  SOFTWARE = 'Software',
  DELIVERY = 'How we deliver',
}