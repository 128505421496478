<script setup lang="ts">
import { Routes } from '@/enums/Routes'
import BurgerButton from '../buttons/BurgerButton.vue'
import WhiteBlocks from '@/components/animations/WhiteBlocks.vue'
import { useNavbar } from '@/composables/useNavbar'
import { isClient } from '@/utils/isClient'

const { clearMenu, burgerButton, animation, menuStatus, tabRouter } = useNavbar()

if (isClient) {
  onMounted(() => {
    window.addEventListener('resize', clearMenu)
  })
  onBeforeUnmount(() => {
    window.removeEventListener('resize', clearMenu)
  })
}
</script>
<template>
  <div>
    <WhiteBlocks :active="animation" />
    <div class="flex items-center">
      <NuxtLink v-if="!animation" :aria-label="`navbar #contact`" :to="Routes.CONTACT" class="button">
        Contact us!
      </NuxtLink>
      <BurgerButton :active="animation" @click="burgerButton" />
    </div>
    <div v-if="menuStatus" class="mobile-navbar">
      <NuxtLink
        v-for="(tab, index) in tabRouter"
        :aria-label="`navbar #${index}`"
        :key="index"
        :to="tab.to"
        @click="clearMenu"
      >
        {{ tab.name }}
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/animation/lineAnimation.css';
.button {
  display: none;

  @media screen and (min-width: 300px) {
    display: block;
    padding: 9px 30px;
    color: var(--vt-c-white);
    border: 1px solid var(--vt-c-white);
    border-radius: 8px;
    margin-right: 24px;
  }
}
.mobile-navbar {
  border-top: 1px solid var(--vt-c-grey-border);
  top: 90px;
  right: 5%;
  position: absolute;
  width: 90%;

  @media (orientation: landscape) and (max-height: 330px) {
    overflow: scroll;
    height: 210px;

    a {
      margin-right: 1rem;
    }
  }

  @media (orientation: landscape) and (max-height: 295px) {
    overflow: scroll;
    height: 180px;

    a {
      margin-right: 1rem;
    }
  }

  a {
    display: block;
    width: 100%;
    color: var(--vt-c-text-dark);
    font-size: 18px;
    line-height: 21px;
    font-weight: 800;
    text-align: center;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    &:first-of-type {
      margin-top: 1rem;
    }
  }
}

@media screen and (min-width: 750px) {
  .mobile-navbar {
    width: 280px;
    position: fixed;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
      width: fit-content;
      text-align: end;
      position: relative;

      &:hover:not(#logo-icon)::after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        background: var(--vt-c-text-light);
        height: 2px;
        width: 100%;
        animation: nav-underline 0.4s ease;
      }
    }
  }
}
</style>
