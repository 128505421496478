import { ref } from 'vue'
import useCss from '@/composables/useCss'
import { useWindow } from '@/composables/useWindow'
import { Colors } from '@/enums/Colors'
import { isClient } from '@/utils/isClient'
import { Routes, RoutesName } from '@/enums/Routes'

const menuStatus = ref(false)
const animation = ref(false)

export const useNavbar = () => {
  const { setProperty, removeClass, addClass } = useCss()
  const screen = useWindow()
  const config = useRuntimeConfig()

  const setAnimation = (arg: boolean) => (animation.value = arg)
  const getStatus = computed(() => menuStatus.value)

  const clearMenu = () => {
    menuStatus.value = false
    animation.value = false
    setProperty('--vt-c-color-logo', Colors.WHITE)
    removeClass('navbar', 'is-active')
    if (isClient) document.body.classList.remove('body-cutter') 
  }

  const burgerButton = () => {
    animation.value = !animation.value
    animation.value
      ? setTimeout(() => (menuStatus.value = animation.value), 300)
      : (menuStatus.value = animation.value)
    if (animation.value) {
      addClass('navbar', 'is-active')
      if (screen.value.width <= 750) {
        setProperty('--vt-c-color-logo', Colors.DARK)
        document.body.classList.add('body-cutter')
      }
    } else clearMenu()
  }

  const tabRouter = [
    {
      name: RoutesName.HOME,
      to: Routes.HOME,
    },
    {
      name: RoutesName.OUR_MODEL,
      to: Routes.OUR_MODEL,
    },
    {
      name: RoutesName.SERVICES,
      to: Routes.SERVICES,
    },
    // {
    //   name: RoutesName.DEV_MARKET,
    //   to: Routes.DEV_MARKET,
    // },
    {
      name: RoutesName.PORTFOLIO,
      to: Routes.PORTFOLIO,
    },
    // {
    //   name: RoutesName.CAREERS,
    //   to: Routes.CAREERS,
    // },
    // {
    //   name: RoutesName.SOFTWARE,
    //   to: Routes.SOFTWARE,
    // },
    // {
    //   name: RoutesName.DELIVERY,
    //   to: Routes.DELIVERY,
    // },
    {
      name: RoutesName.CONTACT,
      to: Routes.CONTACT,
    },
  ]
  
  if (config.public.blogEnabled) {
    const index = tabRouter.findIndex((item) => item.name === RoutesName.PORTFOLIO)
    tabRouter.splice(
      index,
      0,
      {
        name: RoutesName.BLOG,
        to: Routes.BLOG,
      }
    )
  }

  return {
    clearMenu,
    burgerButton,
    setAnimation,
    getStatus,
    animation,
    menuStatus,
    tabRouter,
  }
}
