import { Routes } from '@/enums/Routes'
import { useCareersStore } from '@/store/careers.store'
import { useCommonElementsStore } from '@/store/commonElements.store'
import { useContactStore } from '@/store/contact.store'
import { useMarketplaceStore } from '@/store/devMarketplace.store'
import { useHomeStore } from '@/store/home.store'
import { useOurModelStore } from '@/store/ourModel.store'
import { usePortfolioStore } from '@/store/portfolio.store'
import { useServiceStore } from '@/store/service.store'
import { useDeliverStore } from '@/store/deliver.store'
import { useSoftwareStore } from '@/store/software.store'
import { useBlogStore } from '@/store/blog.store'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const promises = [useCommonElementsStore().fetchFooterSection]
  const config = useRuntimeConfig()

  if (to.path === Routes.HOME) {
    promises.push(
      useHomeStore().fetchData,
      useCommonElementsStore().fetchPanels,
      useCommonElementsStore().fetchTechnologies,
      useCommonElementsStore().fetchJobs
    )
  }

  if (to.path === Routes.OUR_MODEL) {
    promises.push(
      useOurModelStore().fetchData,
      useCommonElementsStore().fetchBusinessCard
    )
  }

  if (to.path === Routes.SERVICES) {
    promises.push(
      useServiceStore().fetchData,
      useCommonElementsStore().fetchPanels,
      useCommonElementsStore().fetchTechnologies,
      useCommonElementsStore().fetchBusinessCard
    )
  }

  if (to.path === Routes.CONTACT) {
    promises.push(
      useMarketplaceStore().fetchData,
      useContactStore().fetchData,
      useCommonElementsStore().fetchBusinessCard
    )
  }

  if (to.path === Routes.DELIVERY) {
    promises.push(
      useDeliverStore().fetchData,
      useCommonElementsStore().fetchBusinessCard,
      useCommonElementsStore().fetchPortfolios,
      useCommonElementsStore().fetchCaseStudies
    )
  }

  if (to.path === Routes.SOFTWARE) {
    promises.push(
      useSoftwareStore().fetchData,
      useCommonElementsStore().fetchBusinessCard,
      useCommonElementsStore().fetchRecomendations,
    )
  }

  if (to.path === Routes.CAREERS) {
    promises.push(
      useCareersStore().fetchData,
      useCommonElementsStore().fetchJobs,
      useMarketplaceStore().fetchData
    )
  }

  if (RegExp(`${Routes.BLOG}`).exec(to.path)) {
    if (!config.public.blogEnabled)
      return navigateTo(Routes.PORTFOLIO)
    
    promises.push(
      useBlogStore().fetchData,
      useCommonElementsStore().fetchBlogPosts,
      useCommonElementsStore().fetchBlogPostTags
    )
  }

  if (RegExp(`${Routes.PORTFOLIO}|${Routes.CASE_STUDY}`).exec(to.path)) {
    promises.push(
      usePortfolioStore().fetchData,
      useCommonElementsStore().fetchTechnologies,
      useCommonElementsStore().fetchBusinessCard,
      useCommonElementsStore().fetchRecomendations,
      useCommonElementsStore().fetchCaseStudies,
      useCommonElementsStore().fetchCategories,
      useCommonElementsStore().fetchPortfolios
    )
  }

  await Promise.all(promises.map(el => el()))
})
